import React, { useState } from 'react'

import {
  Client,
  ToggleSwitch,
  DropDown,
} from '../shared'

import {
  Row,
  Col,
} from 'react-bootstrap'

const FulfillmentOptions = ({
  fulfillmentOptions,
  locationID
}) => {
  const client = new Client

  const [options, setOptions] = useState(fulfillmentOptions)

  const handleChange = (optionType) => {
    const newOptions = {
      ...options,
      [optionType]: !options[optionType]
    }

    setOptions(newOptions)

    client.updateLocation(locationID, {
      meta: newOptions
    })
  }


  const classNames = (...classes) => {
    return classes.filter(Boolean).join(' ')
  }

  return (
    <div class="">
      <p className='text-xl'><strong>Fulfillment Options</strong></p>
      <div>
        <p style={{ fontSize: 20 }}>Turn on fulfillment options you would like offer on the kiosk</p>
        <br />
        {
          Object.keys(fulfillmentOptions).map(optionName => {
            const options = fulfillmentOptions[optionName]
            {
              return (
                <>
                  <div style={{fontSize: 40}}>{optionName}</div>
                  <Row>
                    <Col><strong>name</strong></Col>
                    <Col><strong>delivery option</strong></Col>
                    <Col><strong>payment term</strong></Col>
                    <Col><strong>active</strong></Col>
                  </Row>
                  {
                    options.map(option => {
                      const {
                        location_id,
                        available_options,
                        delivery_option,
                        payment_term,
                        name,
                        active,
                      } = option

                      const [currentPaymentTerm, setCurrentPaymentTerm] = useState(payment_term)

                      return (
                        <Row>
                          <Col>{name}</Col>
                          <Col>{delivery_option}</Col>
                          <Col>
                            <DropDown
                              currentSelected={currentPaymentTerm}
                              selections={available_options.payment_terms}
                              onSelect={(payment_term)=> {
                                setCurrentPaymentTerm(payment_term)

                                client.updateFulFillmentOption(option.id, location_id, {payment_term})
                              }}
                            />
                          </Col>
                          <Col>
                            <ToggleSwitch
                              checked={active}
                              onChange={(isActive)=> {
                                client.updateFulFillmentOption(option.id, location_id, {active: isActive})
                              }}
                            />
                          </Col>
                        </Row>
                      )
                    })
                  }
                </>
              )
            }
          })
        }
      </div>
    </div>
  )

}

export default FulfillmentOptions
