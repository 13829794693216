import React, { useState } from 'react'
import { Formik } from 'formik'
import * as Yup from 'yup'
import DataTable from 'react-data-table-component'
import {
  Button,
  Row,
  Col,
  Form,
  Container,
  Tab,
  Nav
} from 'react-bootstrap'

import {
  TextInput,
  Client,
  styles,
} from '../shared'

import pairing from '../shared/pairing.svg'
import minus from '../shared/minus.svg'
import FulfillmentOptions from './FulfillmentOptions'
import ReceiptCustomization from './ReceiptCustomization'
import OrderConfigs from './OrderConfigs'
import DiscountCodes from './DiscountCodes'

import '../shared/style.css'

const CreateGamsaToken = ({
  secrets,
  setSecrets,
  locationID,
}) => {
  const initialValues = { name: '' }
  const [apiKey, setApiKey] = useState(null)

  const style = {
    rowStyle: { marginLeft: '15' },
    buttonStyle: { marginTop: '23px', fontWeight: 'bold' }
  }

  const client = new Client
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={Yup.object({
        name: Yup.string()
          .required('Required')
      })}
      render={({
        handleSubmit,
        resetForm,
        values,
        touched,
        errors,
        getFieldProps
      }) => {
        const meta = {
          touched,
          errors,
          getFieldProps
        }

        return (
          <div>
            <Form>
              <Row style={style.rowStyle}>
                <Col md={5}>
                  <TextInput
                    field='name'
                    meta={meta}
                    placeholder='ex: counter-1'
                    width='200'
                  />
                </Col>
                <Col md={2}>
                  <button
                    className="rounded-md mt-4 bg-indigo-600 px-2.5 py-1.5 text-md font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    onClick={(e) => {
                      handleSubmit()
                      resetForm()
                      e.preventDefault()

                      const noErrors = Object.keys(errors).length === 0

                      if (noErrors) {
                        client.createKioskToken(locationID, values.name)
                          .then(resp => {
                            const nextSecret = {
                              id: resp.id,
                              name: resp.name,
                            }
                            setSecrets([...secrets, nextSecret])
                            setApiKey(resp.api_secret_token)
                          })
                          .catch(error => console.log(error))
                      }
                    }}
                  >
                    Add a Kiosk
                  </button>
                </Col>
              </Row>
              {
                apiKey ? (
                  <div>
                    <br />
                    <br />
                    <p>Add this API secret to your kiosk:</p>
                    <p><strong>{apiKey}</strong></p>
                    <p>It will not be available once you close or move away from this page.</p>
                  </div>
                ) : (
                  null
                )
              }
            </Form>
          </div>
        )
      }}
    />
  )
}

const KioskPairing = ({
  kiosk,
  client,
}) => {
  const [squareDevicePairingCode, setSquareDevicePairingCode] = useState('')

  const style = {
    span: { fontWeight: 'bold' },
    devicePairing: { fontSize: 18, marginTop: '25px', backgroundColor: "#F0F8FF" }
  }

  return (
    <>
      <button
        className="rounded-md bg-gray-500 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-500"
        onClick={() => {
          client.getSquareDevicePairingCode(kiosk.id)
            .then(resp => {
              setSquareDevicePairingCode(resp)
            })
        }}><span style={style.span}>Get Square Terminal Pairing Code</span></button>
      <div style={style.devicePairing}>
        <p>Device Name: {kiosk.name}</p>
        <p>Device ID: {kiosk.device_id}</p>
        <p>Pairing Code: {squareDevicePairingCode}</p>
      </div>
    </>
  )
}

const ManageEmployees = (props) => {
  const [employees, setEmployees] = useState(props.employees)
  const [newEmployee, setNewEmployee] = useState({name: '', pos_login: '', role: null})

  const client = new Client

  const columns = [
    {
      name: 'Name',
      id: 'name',
      cell: row => {
        return (
          <h3>{row.name}</h3>
        )
      },
    },
    {
      name: 'Passcode',
      id: 'passcode',
      cell: row => {
        return (
          <h3>{row.pos_login}</h3>
        )
      },
    },
    {
      name: 'Role',
      id: 'name',
      cell: row => {
        return (
          <h3>{row.role}</h3>
        )
      },
    },
    {
      name: 'Actions',
      id: 'name',
      cell: row => {
        const onlyOneAdminLeft = employees
          .filter(employee => employee.role === 'admin')
          .length === 1

        return (
          <Button
            variant='danger'
            disabled={onlyOneAdminLeft && row.role === 'admin'}
            onClick={() => {
              client.removeEmployee(row.id, props.locationID)
                .then(resp => {
                  if (resp.ok) {
                    const nextEmployees = employees.filter(employee => {
                      return employee.id !== row.id
                    })

                    setEmployees(nextEmployees)
                  }
                })
            }}
          >
            Delete
          </Button>
        )
      },
    }
  ]

  return (
    <>
      <h1>Employees</h1>
      <Form>
        <Row>
          <Col>
            <Form.Label>
              name
            </Form.Label>
          </Col>
          <Col>
            <Form.Label>
              Passcode
            </Form.Label>
          </Col>
          <Col>
            <Form.Label>
              Position
            </Form.Label>
          </Col>
        </Row>

        <Form.Group>
          <Row>
            <Col>
              <Form.Control
                type="text"
                placeholder="name"
                value={newEmployee.name}
                onChange={(e)=> {
                  setNewEmployee({
                    ...newEmployee,
                    name: e.target.value
                  })
                }}
              />
            </Col>

            <Col>
              <Form.Control
                type="text"
                placeholder="passcode"
                value={newEmployee.pos_login}
                onChange={(e)=> {
                  setNewEmployee({
                    ...newEmployee,
                    pos_login: e.target.value
                  })
                }}
              />
            </Col>

            <Col>
              <Form.Select
                value={newEmployee.role}
                onChange={(e)=> {
                  setNewEmployee({
                    ...newEmployee,
                    role: e.target.value
                  })
                }}
              >
                <option>select one</option>
                <option>admin</option>
                <option>worker</option>
              </Form.Select>
            </Col>
          </Row>
        </Form.Group>
        <Button
          disabled={
            newEmployee.role === null ||
              newEmployee.name === '' ||
              newEmployee.pos_login === ''
          }
          onClick={()=> {
            client.addEmployee(newEmployee, props.locationID)
              .then(resp => {
                if (resp.ok) {
                  setEmployees([newEmployee, ...employees])
                }
              })
          }}
        >
          Add Employee
        </Button>
      </Form>

      <br/>

      <DataTable
        columns={columns}
        data={employees}
        pagination
        paginationComponentOptions={{}}
      />
    </>
  )
}

const ConfigsUI = (props) => {
  const {
    locationID,
    SecretInput,
    fulfillmentOptions,
    receiptText,
    receiptDeliveryOptions,
    claimOrderBy,
    claimOrderByTakeout,
    tippingEnabled,
    specialInstructionsEnabled,
    timeDuration,
    loyaltyEnabled,
    smsEligible,
    discountCodesEnabled,
    employees,
  } = props

  const [secrets, setSecrets] = useState(props.secrets)

  const client = new Client

  const style = {
    div: { marginLeft: 15, marginBottom: 85 },
    span: { fontSize: 20, fontWeight: 'bold' },
    nav: { fontSize: 20, marginTop: 50 },
    tab: { margin: 50, marginBottom: 50 },
    button: { width: 100 },
    revoke: { fontWeight: 'bold' },
    kiosk: { margin: 35 }
  }

  const columns = [
    {
      id: 'name',
      selector: row => {
        return (
          <div style={style.div}>
            <span style={style.span}>{row.name}
            </span>
          </div>
        )
      }
    },
    {
      id: 'kioskPairing',
      cell: row => {
        return (
          <div style={style.kiosk}>
            <KioskPairing
              kiosk={row}
              client={client}
            />
          </div>
        )
      }
    },
  ]

  return (
    <Container>
      <Tab.Container id="left-tabs-example" defaultActiveKey="first">
        <Row>
          <Col sm={2}>
            <Nav style={style.nav} variant="pills" className="flex-column">
              <Nav.Item>
                <Nav.Link eventKey="first">Kiosks</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="second">Fulfillment Options</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="third">Receipt Customization</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="fourth">Order Configs</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="fifth">Discount Codes</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="sixth">Employees</Nav.Link>
              </Nav.Item>
            </Nav>
          </Col>
          <Col sm={9}>
            <Tab.Content style={style.tab} >
              <Tab.Pane eventKey="first">
                <SecretInput
                  secrets={secrets}
                  locationID={locationID}
                  setSecrets={setSecrets}
                />
                <br />
                <DataTable
                  columns={columns}
                  noTableHead
                  data={secrets}
                  customStyles={styles.dataTableStyles}
                />
              </Tab.Pane>
              <Tab.Pane eventKey="second">
                <FulfillmentOptions
                  fulfillmentOptions={fulfillmentOptions}
                  locationID={locationID} />
              </Tab.Pane>
              <Tab.Pane eventKey="third">
                <ReceiptCustomization
                  receiptText={receiptText}
                  receiptDeliveryOptions={receiptDeliveryOptions}
                  locationID={locationID}
                />
              </Tab.Pane>
              <Tab.Pane eventKey="fourth">
                <OrderConfigs
                  locationID={locationID}
                  claimOrderBy={claimOrderBy}
                  claimOrderByTakeout={claimOrderByTakeout}
                  tippingEnabled={tippingEnabled}
                  specialInstructionsEnabled={specialInstructionsEnabled}
                  timeDuration={timeDuration}
                  loyaltyEnabled={loyaltyEnabled}
                  smsEligible={smsEligible}
                />
              </Tab.Pane>
              <Tab.Pane eventKey="fifth">
                <DiscountCodes locationID={locationID} discountCodesEnabled={discountCodesEnabled} />
              </Tab.Pane>
              <Tab.Pane eventKey="sixth">
                <ManageEmployees
                  employees={employees}
                  locationID={locationID}
                />
              </Tab.Pane>
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
    </Container>
  )
}

const LocationIndex = ({
  locations,
  showReceiptOptions,
}) => {

  const columns = [
    {
      name: 'Name',
      selector: row => row.location_name,
    },

    {
      name: 'Address',
      selector: row => `${row.line_1} ${row.line_2 || ''}`,
    },

    {
      name: 'City',
      selector: row => row.city,
    },

    {
      name: 'State',
      selector: row => row.state,
    },

    {
      name: 'Reports',
      selector: row => row.location_id,
      cell: row => {
        return <button
          type="button"
          className="rounded-md bg-indigo-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          onClick={() => {
            window.location.href = `/locations/${row.location_id}/reports`
          }}
        >
          Orders
        </button>
      }
    },
  ]

  const rowStyles = {
    location: { marginLeft: 90, marginTop: 50, marginBottom: 25 },
    secrets: { marginLeft: 100, marginRight: 100, marginTop: 50 }
  }

  return (
    <>
      <Row style={rowStyles.location}>
        <h1><strong>Locations</strong></h1>
      </Row>
      <Row style={rowStyles.secrets}>
        <DataTable
          columns={columns}
          data={locations}
          persistTableHead
          fixedHeader
          expandableRows
          expandableRowExpanded={row => false}
          expandableRowsComponent={(row) => {
            return (
              <Configs
                data={row.data}
              />
            )
          }}
          keyField={'id'}
          customStyles={styles.dataTableStyles}
        />
      </Row>
    </>
  )
}

const StoreGloriafoodsTokenForm = ({
  secrets,
  setSecrets,
  locationID,
}) => {
  const initialValues = { secret: '' }
  const client = new Client
  const addButtonDisabled = secrets.length > 0

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={Yup.object({
        secret: Yup.string()
          .required('Required')
      })}
      render={({
        handleSubmit,
        resetForm,
        values,
        touched,
        errors,
        getFieldProps
      }) => {
        const meta = {
          touched,
          errors,
          getFieldProps
        }

        return (
          <Form>
            <Row>
              <Col md={6}>
                <TextInput
                  field='secret'
                  meta={meta}
                  placeholder='Paste token here'
                />
                <Form.Select>
                  <option value="gloriafoods">Gloriafoods</option>
                </Form.Select>
              </Col>
              <Col md={3} style={{ marginTop: 32 }}>
                <Button
                  variant='success'
                  disabled={addButtonDisabled}
                  onClick={(e) => {
                    handleSubmit()
                    resetForm()
                    e.preventDefault()

                    const noErrors = Object.keys(errors).length === 0

                    if (noErrors) {
                      client.createLocationSecret(locationID, values.secret, 'gloriafoods')
                        .then(resp => {
                          const nextSecret = {
                            id: resp.id,
                            name: resp.name,
                          }
                          setSecrets([...secrets, nextSecret])
                        })
                        .catch(error => console.log(error))
                    }
                  }}
                >
                  Add Token
                </Button>
              </Col>
            </Row>
          </Form>
        )
      }}
    />
  )
}

const Configs = ({
  data,
}) => {
  return (
    <Row>
      <ConfigsUI
        secrets={data.kiosks}
        locationID={data.location_id}
        SecretInput={CreateGamsaToken}
        receiptText={data.receipt}
        receiptDeliveryOptions={data.receipt_delivery}
        claimOrderBy={data.claim_order_by}
        claimOrderByTakeout={data.claim_order_by_takeout}
        tippingEnabled={data.tipping_enabled}
        specialInstructionsEnabled={data.order_special_instructions_enabled}
        timeDuration={data.order_time_duration}
        loyaltyEnabled={data.loyalty_program_enabled}
        smsEligible={data.sms_eligible}
        discountCodesEnabled={data.discount_codes_enabled}
        fulfillmentOptions={data.fulfillment_options}
        employees={data.employees}
      />
    </Row>
  )
}

export default LocationIndex
